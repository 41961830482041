.ant-table-cell {
  /* fixed during table height issue - prev value 10.5px  - 04/12/2023*/
  padding: 6px 24px !important;
  background-color: #fafafa !important;
  border-inline-end: unset !important;
}

.custom-header-background .ant-table-thead > tr > th {
  background-color: #f9f9f9 !important;
}

.ant-table-cell-row-hover {
  background-color: #f8f6f4 !important;
}

.ant-table-tbody > .ant-table-row.selected-row .ant-table-cell {
  background-color: var(
    --primary-20
  ) !important; /* Light blue background for selected rows */
}

.ant-table-tbody > .ant-table-row.deleted-row .ant-table-cell {
  background-color: #f9ecea !important;
}

.ant-table-column-sorters {
  justify-content: start !important;
}
.ant-table-column-title {
  flex: unset !important;
}
.ant-table-column-sorter-inner {
  color: #626263 !important;
}

/* width of the scrollbar */
::-webkit-scrollbar {
  width: 6px;
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
  border-radius: 2px;
  background: #fafafa !important;
}

/* handle of the scrollbar */
::-webkit-scrollbar-thumb {
  background: #dfdfdf !important;
  border-radius: 4px;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  /* IE and Edge */
  -ms-overflow-style: none !important;
  /* Firefox */
  scrollbar-width: none !important;
}
.ant-table-wrapper .ant-table-column-sorter-up,
.ant-table-wrapper .ant-table-column-sorter-down {
  font-size: 8px !important;
}
.ant-table-wrapper
  .ant-table-column-sorter
  .ant-table-column-sorter-up
  + .ant-table-column-sorter-down {
  margin-top: -0.15em !important;
}
[class^="ant-table"] [class^="ant-table"],
[class*=" ant-table"] [class^="ant-table"],
[class^="ant-table"] [class*=" ant-table"],
[class*=" ant-table"] [class*=" ant-table"] {
  border-radius: 0px !important;
}
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--primary) !important;
  box-shadow: unset !important;
}
/* .ant-checkbox .ant-checkbox-inner {
  border: unset !important;
  border-bottom: 2px solid #4c9a58 !important;
  border-right: 2px solid #4c9a58 !important;
} */
/* --------- Custom input -------------- */

/* .ant-input:hover,
.ant-input:focus {
  border-color: #dfdfdf !important;
  box-shadow: none;
} */
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item,
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-submenu-title {
  padding: 0px !important;
}

/* custom input borders */

.primary-border {
  border: 1px solid #dfdfdf !important;
}

.success-border {
  border: 1px solid var(--primary) !important;
}

.error-border {
  border: 1px solid #c84630 !important;
}

.ant-checkbox .ant-checkbox-inner:after {
  /* border: 2px 2px 2px 0px solid #4c9a58 !important; */
  border: unset !important;
  /* border-bottom: 2px solid var(--primary) !important; */
  border-bottom: 2px solid !important;

  /* border-left: 2px solid var(--primary) !important; */
  /* border-right: 2px solid var(--primary) !important; */
  border-right: 2px solid !important;
}

/* .ant-checkbox-checked .ant-checkbox-inner { */
/* background-color: unset !important; */
/* border-color: var(--primary) !important; */
/* } */

.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
  .ant-checkbox-checked:not(.ant-checkbox-disabled):after {
  border-color: unset !important;
  border: unset !important;
}
.ant-checkbox::after {
  border: unset !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary) !important;
}

.ant-modal-content {
  padding: unset !important;
}

.ant-modal .ant-modal-content {
  border-radius: 12px !important;
}

.leaflet-popup-content-wrapper {
  /* padding: unset !important; */
  border-radius: 0.5rem !important;
}

.leaflet-div-icon {
  background: none !important;
  border: none !important;
}

.leaflet-popup-close-button {
  display: none;
}
.leaflet-popup-content {
  margin: unset !important;
}

.leaflet-control-attribution {
  display: none !important;
}

.ant-drawer-body {
  padding: unset !important;
}

.ant-drawer-content-wrapper {
  margin-top: 72px !important;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03) !important;
  border-radius: 12px 0px 0px 12px !important;
}

.table-container {
  transition: height 0.5s ease;
}

.leaflet-bottom .leaflet-right .leaflet-control-attribution .leaflet-control {
  display: none !important;
}
.leaflet-interactive {
  /* stroke: #4c9a58 !important; */
  /* stroke-width: 1.5 !important; */
  fill: none !important;
  /* fill-opacity: 0.2 !important; */
}

.ant-select-selection-placeholder {
  color: #525252 !important;
  font-family: "Aileron" !important;
  font-weight: 500 !important;
  font-size: 0.75rem !important;
  opacity: 1 !important;
}

.ant-input::placeholder {
  color: #525252 !important;
  font-family: "Aileron" !important;
  font-weight: 400 !important;
  font-size: 0.75rem !important;
  opacity: 1 !important;
}

/* .ant-select-selection-placeholder {
  color: #f0f0f0;
} */

.ant-popover-inner {
  border-radius: 12px !important;
  background-clip: unset !important;
  padding: unset !important;
}

.ant-radio-wrapper .ant-radio-inner::after {
  background-color: var(--primary) !important;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: var(--primary) !important;
  background-color: #f1f7ff !important;
}

.ant-radio-wrapper .ant-radio-checked::after {
  border-color: var(--primary) !important;
}

/* Crop modal */

.ant-slider .ant-slider-rail {
  height: 8px !important;
  border-radius: 4px !important;
}

.ant-slider-handle::before {
  height: 24px !important;
  width: 24px !important;
}

.ant-slider-handle::after {
  margin-top: -4px !important;
  height: 24px !important;
  width: 24px !important;
}

.image-crop-modal .ant-modal-content {
  border-radius: 12px;
  padding: 0px;
}
.image-crop-modal .ant-modal-content .ant-modal-header {
  border-radius: 12px 12px 0 0;
}
.image-crop-modal .ant-modal-content .ant-modal-header .ant-modal-title {
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  text-transform: capitalize;
}
.image-crop-modal .ant-modal-content .ant-modal-footer button {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  background-color: #f8f6f4;
  border-radius: 6px;
  font-weight: 500;
  outline: none;
  border: none;
  box-shadow: none;
  width: 75px;
}
.image-crop-modal .ant-btn-default:not(:disabled):hover {
  color: #000000;
}
.image-crop-modal .ant-modal-content .ant-modal-footer {
  padding: 0 16px 16px;
}
.image-crop-modal .ant-modal-content .ant-modal-footer .ant-btn-primary {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  background: var(--primary);
  outline: none;
  border: none;
  box-shadow: none;
  width: 62px;
}

.image-crop-modal .ant-slider .ant-slider-track {
  height: 8px !important;
  border-radius: 4px !important;
  background-color: var(--primary);
}

.image-crop-modal .ant-modal-body {
  padding: 0px;
}

.image-crop-modal .ant-modal-header {
  padding: 16px;
  margin-bottom: 0px;
}

.image-crop-modal .ant-modal-footer {
  padding: 16px;
  margin-top: 0px;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-picker-input > input::placeholder {
  color: #525252 !important;
  font-weight: 400 !important;
  font-size: 0.8125rem !important;
}

/* Table pagination */
.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  border-top: 1px solid #f5f5f5 !important;
  background: #fafafa !important;
  margin: 0px !important;
  padding: 8px 16px !important;
  border-bottom-left-radius: 16px !important;
  border-bottom-right-radius: 16px;
  flex: 1;
  align-items: center !important;
  justify-content: center !important;
}

.ant-pagination .ant-pagination-item {
  border: none !important;
  border-radius: 50px;
  font-family: "aileron" !important;
  font-size: 10px !important;
  font-weight: 400 !important;
}

.ant-pagination .ant-pagination-item a {
  color: #525252 !important;
}

.ant-pagination .ant-pagination-item-active {
  background-color: #f8f6f4 !important;
}

.ant-table-tbody > .ant-table-row.cannot-delete-row .ant-table-cell {
  background-color: #f8f6f4 !important;
}

.ant-switch .ant-switch-inner {
  background: #fff !important;
  /* border: 1px solid "#6F6F6F" !important; */
  border-width: 1px !important;
}

.ant-switch.ant-switch-checked .ant-switch-inner {
  background: #085054 !important;
  border-width: 0px !important;
}
