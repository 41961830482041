@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --primary: #085054;
    --primary-50: #528587;
    --primary-20: #cedcdd;
    --primary-10: #e6edee;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fad152;
    --sidebar-inactive: #fad152;
    --kpi-background: #fad152;
    --kpi-icon: #085054;
    --kpi-details-icon: #fad152;
    --source-and-destination-tag: #eab726;
    --trip-kpi-title: #525252;
    --trip-kpi-value: #000000;
  }
  .green-theme {
    --primary: #085054;
    --primary-50: #528587;
    --primary-20: #cedcdd;
    --primary-10: #e6edee;
    --secondary: "#FAD152";
    --sidebar-base: #aabdbd;
    --sidebar-active: #fad152;
    --sidebar-inactive: #fad152;
    --kpi-background: #fad152;
    --kpi-icon: #085054;
    --kpi-details-icon: #fad152;
    --source-and-destination-tag: #eab726;
    --trip-kpi-title: #525252;
    --trip-kpi-value: #000000;
  }

  .blue-theme {
    --primary: #135bba;
    --primary-50: #5a8ccf;
    --primary-20: #d0def1;
    --primary-10: #e7eff8;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #135bba;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #135bba;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }
  .pink-theme {
    --primary: #dd6898;
    --primary-50: #e795b7;
    --primary-20: #f8e1ea;
    --primary-10: #fcf0f5;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #dd6898;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #dd6898;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }
  .purple-theme {
    --primary: #aa68d9;
    --primary-50: #c395e4;
    --primary-20: #eee1f7;
    --primary-10: #f7f0fb;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #aa68d9;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #aa68d9;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }

  .brown-theme {
    --primary: #8e786c;
    --primary-50: #b0a098;
    --primary-20: #e8e4e2;
    --primary-10: #f4f1f0;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #8e786c;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #8e786c;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }
  .grey-theme {
    --primary: #878787;
    --primary-50: #ababab;
    --primary-20: #e7e7e7;
    --primary-10: #f3f3f3;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #878787;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #878787;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }
  .black-theme {
    --primary: #3a3b3e;
    --primary-50: #757678;
    --primary-20: #d8d8d8;
    --primary-10: #ebebec;
    --sidebar-base: #aabdbd;
    --sidebar-active: #fff;
    --sidebar-inactive: #fad152;
    --kpi-background: #3a3b3e;
    --kpi-icon: #fff;
    --kpi-details-icon: #fff;
    --source-and-destination-tag: #3a3b3e;
    --trip-kpi-title: #fff;
    --trip-kpi-value: #fff;
  }
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Black.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-BoldItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Italic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-HeavyItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-HeavyItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-LightItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-BlackItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Bold.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Light.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Heavy.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Thin.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-SemiBold.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-SemiBoldItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-ThinItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-UltraLightItalic.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-UltraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-Regular.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Aileron";
  src: url("fonts/Aileron/Aileron-UltraLight.woff2") format("woff2"),
    url("fonts/Aileron/Aileron-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
